import * as React from "react";
import {createContext, useContext, useEffect, useState} from "react";
import {Store} from "../types/Store";
import {motion} from "framer-motion";
import ConnectToShopifyStep from "../pages/onboarding/ConnectToShopifyStep";
import IntegrateWithShopifyStep from "../pages/onboarding/IntegrateWithShopifyStep";
import ConfigureAppStep from "../pages/onboarding/ConfigureAppStep";
import axios from "axios";
import {getBackendURL} from "../utils/EnvironmentsManager";
import {LicenseStatus} from "../enums/LicenseStatus";
import {toastError} from "../utils/ErrorHandlerUtils";
import Spinner from "../components/loading/Spinner";
import DropdownMenu, {MenuOption} from "../components/input-fields/DropdownMenu";
import {FormProvider, useForm} from "react-hook-form";
import toast from "react-hot-toast";
import {useNavigate} from "react-router";
import SetUpAppStep from "../pages/onboarding/SetUpAppStep";
import FinalStep from "../pages/onboarding/FinalStep";

interface StoreHolder {
    store?: Store
    stores?: Store[]
    switchStore?: (store: Store) => void
    reloadStores?: () => void
}

export const StoreContext = createContext<StoreHolder>({})

export const useStoreProvider = () => {
    const context = useContext(StoreContext);
    if (!context) {
        throw new Error("useStoreProvider must be used within a StoreHolder area");
    }
    return context;
}

export interface StoreProviderProps {
    children: React.ReactNode
    onStoreChange: (store: Store) => void
}

export const StoreProvider = ({children, onStoreChange}: StoreProviderProps) => {
    const [storeCredentialsRegistered, setStoreCredentialsRegistered] = useState<boolean | undefined>(undefined)
    const [step, setStep] = useState<number | undefined>(undefined)
    const [store, setStore] = useState<Store | undefined>(undefined)
    const [creatingPortalLink, setCreatingPortalLink] = useState<boolean>(false)
    const [stores, setStores] = useState<Store[] | undefined>([])
    const [storesMenuOptions, setStoresMenuOptions] = useState<MenuOption[]>([])
    const navigate = useNavigate()

    const methods = useForm();

    useEffect(() => {
        reloadStores()
    }, []);

    const reloadStores = () => {
        loadStore()
        getStores()
    }

    const loadStore = () => {
        axios.get(getBackendURL() + "/api/v1/store").then(result => {
            let store = result.data as Store;
            setStore(store)
            onStoreChange(store)

            setStoreCredentialsRegistered(store.credentialsForStoreRegistered)
            if (!store?.credentialsForStoreRegistered) {
                setStep(0)
            }
        })
    }

    const getStores = () => {
        axios.get(getBackendURL() + "/api/v1/store/list").then(result => {
            let stores = result.data as Store[];
            setStores(stores)
            setStoresMenuOptions(stores.map(store => {
                return {title: store.name + ".myshopify.com", id: store.id}
            }))
        })
    }

    const userPressedNext = () => {
        setStep(step! + 1)
    }

    const userPressedFinalNext = () => {
        reloadStores()
        navigate("/")
    }

    const userPressedBack = () => {
        setStep(step! - 1)
    }

    const createCustomerPortalLinkAndRedirect = () => {
        setCreatingPortalLink(true)
        axios.post(getBackendURL() + "/api/v1/user/create-customer-portal-link").then(result => {
            window.location.href = result.data
        }).catch(error => {
            toastError(error)
            setCreatingPortalLink(false)
        })
    }

    const addStorePressed = () => {
        navigate("/add-store")
    }

    const switchStore = (store: Store) => {
        axios.put(getBackendURL() + "/api/v1/store/switch/" + store.id).then(result => {
            reloadStores()
        })
    }

    return (
        <StoreContext.Provider value={{store, stores, switchStore, reloadStores}}>
            {stores && store && (
                <>
                    <FormProvider {...methods}>
                        <form className={" hidden md:flex ml-auto mr-10 mt-4 w-52"}>
                            <DropdownMenu
                                name={"store"}
                                label={"Butikk"}
                                options={storesMenuOptions}
                                defaultValue={store.id}
                                onChange={(menuOption) => switchStore(stores?.filter(b => b.id == menuOption?.id)[0])}
                                actionButtonText={"Legg til nettbutikk"}
                                onActionButtonClicked={addStorePressed}
                                hideEmpty={true}
                                icon={"/shopify.svg"}
                            />
                        </form>
                    </FormProvider>

                </>
            )}

            {(storeCredentialsRegistered === null || storeCredentialsRegistered === false) ? (
                <div>
                    <motion.div
                        initial={{opacity: 0}}
                        animate={{opacity: 1}}
                        transition={{delay: 0.2}}
                        className={"-z-20 absolute top-10 left-[50%] -translate-x-1/2 w-[500px] text-center bg-red-500 py-2 px-4 rounded-xl mb-4 font-bold text-lg text-white"}>
                        Vi får ikke kontakt med shopify butikken din. Sørg for at den riktige informasjonen er
                        oppgitt under.
                    </motion.div>

                    <div
                        className={"absolute top-0 flex flex-col justify-center items-center w-screen h-screen -z-10"}
                    >
                        <IntegrateWithShopifyStep onNextPressed={userPressedNext} stepVisible={step == 0}/>
                        <ConfigureAppStep onBackPressed={userPressedBack} onNextPressed={userPressedNext}
                                          stepVisible={step === 1}/>
                        <SetUpAppStep onNextPressed={userPressedNext} onBackPressed={userPressedBack}
                                      stepVisible={step === 2}/>
                        <ConnectToShopifyStep onBackPressed={userPressedBack} onNextPressed={userPressedNext}
                                              stepVisible={step == 3}/>
                        <FinalStep onNextPressed={userPressedFinalNext} stepVisible={step === 4}/>
                    </div>
                </div>
            ) : (
                <>
                    {store?.license.status == LicenseStatus.OVERDUE && (
                        <div
                            className={"mx-auto w-[95%] mt-5 text-center py-2 px-4 rounded-xl font-bold text-lg text-white md:mb-10 md:w-[500px] lg:w-[600px] " + (creatingPortalLink || "bg-red-500")}>
                            {creatingPortalLink ? (
                                <Spinner className={"flex justify-center"}/>
                            ) : (
                                <>
                                    Noe gikk galt ved forrige betaling av ditt abonnement. Vi kommer til å forsøke igjen
                                    i løpet av de neste dagene. Sørg for at betalingsinformasjonen er oppdatert <span
                                    className={"text-purple-200 underline cursor-pointer"}
                                    onClick={createCustomerPortalLinkAndRedirect}>her</span>
                                </>
                            )}
                        </div>
                    )}

                    {children}
                </>
            )}


        </StoreContext.Provider>
    )
}