import {createContext, useContext, useEffect, useState} from "react";
import axios from "axios";
import {getBackendURL} from "../utils/EnvironmentsManager";
import {useNavigate} from "react-router";
import privacyPolicy from "../pages/legal-pages/PrivacyPolicy";

interface LoginHolder {
    authenticated: boolean
    checkLogin: () => void
}

export const LoginContext = createContext<LoginHolder>({
    checkLogin: () => {},
    authenticated: false
})

export const useLoginProvider = () => {
    const context = useContext(LoginContext);
    if (!context) {
        throw new Error("useLoginProvider must be used within an LoginProvider");
    }
    return context;
}

export interface LoginProviderProps {
    children: React.ReactNode
}

export const LoginProvider = ({children}: LoginProviderProps) => {
    const [authenticated, setAuthenticated] = useState<boolean>(false)
    const navigate = useNavigate()

    const publicPaths = ["login", "register","register/suksessklubb", "change-password", "forgot-password", "terms-of-service", "privacy-policy","delete-me"]

    useEffect(() => {
        checkLogin()
    }, []);

    const checkLogin = () => {
        axios.get(getBackendURL() + "/api/v1/user/authenticated").then((result) => {
            console.log(result.data)
            setAuthenticated(true)
        }).catch(error => {
            setAuthenticated(false)
            // navigate to /login if the url is not a public path
            if (!publicPaths.includes(window.location.pathname.split("/")[1])) {
                navigate("/login")
            }
        });
    }

    return (
        <LoginContext.Provider value={{checkLogin, authenticated}}>
            {children}
        </LoginContext.Provider>
    )
}