import React, {useState, useEffect} from "react";
import {FieldValues, FormProvider, useForm} from "react-hook-form";
import Heading2 from "../../components/text/Heading2";
import InputTextField from "../../components/input-fields/InputTextField";
import MainButton from "../../components/buttons/MainButton";
import PasswordInput from "../../components/input-fields/PasswordInput";
import CheckBoxComponent from "../../components/checkboxes/CheckBoxComponent";
import GoogleButton from "../../components/buttons/GoogleButton";
import DividerWithText from "../../components/authentication/DividerWithText";
import {useNavigate} from "react-router";
import {Link} from "react-router-dom";
import axios from "axios";
import {getBackendURL} from "../../utils/EnvironmentsManager";
import {useLoginProvider} from "../../providers/LoginProvider";
import LinkText from "../../components/input-fields/LinkText";
import FacebookButton from "../../components/buttons/FacebookButton";
import {CheckCircleIcon} from "@heroicons/react/24/outline";
import SuksessklubbButton from "./components/SuksessklubbButton";

const SignUpPage = () => {
    const {checkLogin} = useLoginProvider();
    const methods = useForm();
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [password, setPassword] = useState('');
    const [isSuksessklubb, setIsSuksessklubb] = useState(false);

    // Handle password input
    const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(e.target.value);
    };

    // Check if the URL contains "suksessklubb" (case-insensitive)
    useEffect(() => {
        const isSuksessklubbUrl = window.location.pathname.toLowerCase().includes("suksessklubb");
        setIsSuksessklubb(isSuksessklubbUrl);
    }, []);

    // Handle form submission
    const onSubmit = (values: FieldValues) => {
        const data = {
            "firstName": values.firstName,
            "lastName": values.lastName,
            "email": values.email,
            "password": password
        };

        axios.post(getBackendURL() + "/api/v1/user/register", data).then((response) => {
            window.location.href = "/";
        }).catch((error) => {
            setErrorMessage(error.response.data.error);
        });
    };

    return (
        <>
            <FormProvider {...methods}>
                <div className="min-h-screen flex flex-col lg:flex-row lg:justify-between">
                    {/* Left Section - Bullet Points */}
                    <div className="hidden lg:flex lg:flex-col lg:w-1/2 lg:justify-center mb-20">
                        <div className="m-auto px-10 space-y-8">
                            <div className={"flex items-center gap-5"}>
                                <img
                                    className="mx-auto h-10 w-auto"
                                    src="/enthemed-1.png"
                                    alt="Enthemed"
                                />
                                {isSuksessklubb && (
                                    <>
                                        <span className="text-xl font-bold">X</span>
                                        <img
                                            className="mx-auto h-10 w-auto"
                                            src="/suksessklubblogo.png"
                                            alt="Suksessklubb"
                                        />
                                    </>
                                )}
                            </div>
                            <h1 className="text-xl font-bold">Lanser & Skaler din Shopify Nettbutikk enklere.</h1>
                            <ul className="mt-4 space-y-4">
                                <li className="flex items-start">
                                    <CheckCircleIcon className="text-main-gradient-first h-6 w-8 shrink-0" />
                                    <div className="ml-2">
                                        <strong>Fra idé til ferdig nettbutikk på få minutter</strong>
                                        <p>Lanser nettbutikken din idag.</p>
                                    </div>
                                </li>
                                <li className="flex items-start">
                                    <CheckCircleIcon className="text-main-gradient-first h-6 w-8 shrink-0" />
                                    <div className="ml-2">
                                        <strong>Ferdiglagde nettbutikk temaer</strong>
                                        <p>Optimalisert etter millioner i salg.</p>
                                    </div>
                                </li>
                                <li className="flex items-start">
                                    <CheckCircleIcon className="text-main-gradient-first h-6 w-8 shrink-0" />
                                    <div className="ml-2">
                                        <strong>Flere innebygde apper for økt salg</strong>
                                        <p>Ikke lenger en haug med apper og mange abonnementer.</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>

                    {/* Right Section - Form */}
                    <div className="lg:w-1/2 flex flex-col px-4 justify-center">
                        <div className="my-5 sm:max-w-[480px] sm:mx-auto sm:w-full lg:my-0 lg:py-12">
                            <div className="bg-white px-6 py-8 shadow-lg rounded-lg sm:px-12 border border-gray-200">
                                <img
                                    className="mx-auto h-10 w-auto"
                                    src="/Enthemed-icon-1.png"
                                    alt="Enthemed"
                                />
                                <Heading2 className={"flex justify-center text-2xl mb-8 mt-5"}>
                                    Prøv Enthemed gratis i 10 dager!
                                </Heading2>

                                {errorMessage && <div className={"font-bold text-red-500 text-center"}>{errorMessage}</div>}
                                <form className="mt-5 space-y-4" onSubmit={methods.handleSubmit(onSubmit)}>
                                    <InputTextField
                                        name={"firstName"}
                                        type={"firstName"}
                                        label={"Fornavn"}
                                        style={{width: "100%"}}
                                        validation={{
                                            required: {value: true, message: 'First name is required'},
                                            maxLength: {value: 50, message: "The Name is too long"},
                                        }}
                                    />
                                    <InputTextField
                                        name={"lastName"}
                                        type={"lastName"}
                                        label={"Etternavn"}
                                        style={{width: "100%"}}
                                        validation={{
                                            required: {value: true, message: 'the Last name is required'},
                                            maxLength: {value: 50, message: "The Name is too long"},
                                        }}
                                    />
                                    <InputTextField
                                        name={"email"}
                                        type={"email"}
                                        label={"E-post"}
                                        style={{width: "100%"}}
                                        validation={{
                                            required: {value: true, message: 'Email is required'},
                                            maxLength: {value: 50, message: "The Email is too long"},
                                        }}
                                    />
                                    <PasswordInput
                                        name={"password"}
                                        label="Passord"
                                        value={password}
                                        style={{width: "100%"}}
                                        onValueChange={handlePasswordChange}
                                    />

                                    <div className="flex items-center justify-between">
                                        <CheckBoxComponent
                                            name={"terms"}
                                            validation={{required: {value: true, message: 'You must accept the terms and conditions'}}}
                                        >
                                            Jeg har lest og godtatt{" "}
                                            <Link to="/terms-of-service" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'underline' }}>
                                                kjøpsvilkårene
                                            </Link>{" "}
                                            og{" "}
                                            <Link to="/privacy-policy" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'underline' }}>
                                                personvernerklæringen
                                            </Link>.
                                        </CheckBoxComponent>
                                    </div>
                                    {isSuksessklubb ? (
                                        <SuksessklubbButton className={"w-full"}>Registrer konto nå</SuksessklubbButton>
                                    ) : (
                                        <MainButton className={"w-full"}>Registrer konto nå</MainButton>
                                    )}
                                </form>

                                <div>
                                    <div className="relative mt-6">
                                        <DividerWithText>eller registrer deg med</DividerWithText>
                                    </div>
                                    <div className="mt-6 grid grid-cols-1 gap-4">
                                        <GoogleButton />
                                        <FacebookButton />
                                    </div>
                                    <div className={"mt-5 text-center text-sm"}>
                                        <p className={"text-gray-400"}>
                                            Har du allerede en konto?
                                            <LinkText href={"/login"}>
                                                Logg inn
                                            </LinkText>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </FormProvider>
        </>
    );
};

export default SignUpPage;
